import React, { useEffect, useState } from "react"
import ArrowRightSVG from "../SVGs/arrow-right.inline.svg"
import SearchSVG from "../SVGs/search.inline.svg"
import { graphql, useStaticQuery } from "gatsby"
import { StringParam, useQueryParam } from "use-query-params"

interface IFaqForm {
  onExploreRolesClick?: Function
  clearState?: Function
}

const FaqForm: React.FC<IFaqForm> = props => {
  const data = useStaticQuery(graphql`
    query AllFaqCategories {
      allContentfulCareersFaqItem {
        categories: distinct(field: category)
      }
    }
  `)

  const [filters, setFilters] = useState({ keyword: "", category: [] })
  const [keyword, setKeyword] = useQueryParam("keyword", StringParam)
  const [category, setCategory] = useQueryParam("category", StringParam)

  useEffect(() => {
    setFilters({ category, keyword })
  }, [category, keyword])

  const uriFns = {
    keyword: setKeyword,
    category: setCategory,
  }

  const onChange = (field: string, value: string) =>
    setFilters({ ...filters, [field]: value === "" ? undefined : value })

  const onExploreRolesClick = () => {
    if (props.onExploreRolesClick) {
      props.onExploreRolesClick(filters)
      return
    }
    if (props.clearState) {
      props.clearState()
    }

    Object.keys(filters).forEach(index => uriFns[index](filters[index]))
  }

  const onClearFiltersClick = () => {
    setFilters({ keyword: "", category: [] })
  }

  return (
    <div id="job-search">
      <fieldset>
        <legend className="sr-only">Search FAQ</legend>
        <div className="form-group">
          <label htmlFor="job-search-keyword" className="sr-only">
            {" "}
            Search by Keyword <span className="required">*</span>
          </label>
          <div className="input-group">
            <input
              id="job-search-keyword"
              type="text"
              name="keyword"
              className="form-control"
              placeholder="Keyword"
              aria-label="Keyword"
              value={filters.keyword}
              onChange={e => onChange("keyword", e.target.value)}
            />
            <div className="input-group-append">
              <span className="input-group-text">
                <SearchSVG className="icon-sprite" />
              </span>
            </div>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="job-search-team" className="sr-only">
            {" "}
            Category <span className="required">*</span>
          </label>
          <div className="styled-dropdown">
            <select
              name="category"
              id="job-search-team"
              className="form-control"
              value={filters.category}
              onChange={e => onChange("category", e.target.value)}
            >
              <option value="">All categories</option>
              {data.allContentfulCareersFaqItem.categories.map(
                (team: string) => (
                  <option value={team}>{team}</option>
                )
              )}
            </select>
          </div>
        </div>
        <div className="form-group form-group--buttons">
          <button
            className="btn btn-light has-addon"
            type="submit"
            onClick={onExploreRolesClick}
          >
            Filter FAQ{" "}
            <span className="btn-addon bg-info">
              <ArrowRightSVG className="icon-sprite" />
            </span>
          </button>
          <button className="clear-filters" onClick={onClearFiltersClick}>
            Clear all filters
          </button>
        </div>
      </fieldset>
    </div>
  )
}

export default FaqForm
