import React, {useEffect, useState} from "react"
import CloseSVG from "../SVGs/close.inline.svg"
import FaqForm from "./form"
import {isInBrowser, showForm} from "../../utils/helpers"

const MobileFilter: React.FC = () => {
  const mq = (mediaQuery: string) => {
    if (isInBrowser()) {
      const matchMedia = window.matchMedia || window.msMatchMedia
      if (matchMedia) {
        const mql = matchMedia(mediaQuery)
        return mql && (mql.matches || false)
      }
    }

    return false
  }

  const [showModal, setShowModal] = useState(false)
  const [isMobile, setIsMobile] = useState(showForm())

  const getFilterClasses = () =>
    `js-filter-modal-mobile card card-job-search${isMobile ? " modal fade" : ""}${
      showModal ? " show" : ""
    }`

  const [classes, setClasses] = useState(getFilterClasses())

  useEffect(() => {
    if (isInBrowser()) {
      window.addEventListener("resize", () =>
        setIsMobile(showForm())
      )
    }
  }, [])

  useEffect(() => {
    setClasses(getFilterClasses())
  }, [showModal, isMobile])

  return (
    <>
      <button
        type="button"
        className="mobile-filter"
        data-toggle="modal"
        data-target="#js-filter-modal"
        onClick={() => setShowModal(true)}
      >
        + Add Filter
      </button>
      <div
        id="js-filter-modal"
        className={classes}
        style={{display: (showModal ? "block" : "")}}
      >
        <div className="modal-dialog" role="document">
        <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setShowModal(false)}
          >
            <CloseSVG className="icon-sprite"/>
          </button>
          <h3 className="filter-heading"> FILTER FAQS </h3>
          <div className="form-wrap clearfix">
            <FaqForm clearState={() => setShowModal(false)}/>
          </div>
        </div>
      </div>
    </>
  )
}

export default MobileFilter
