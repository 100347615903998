import React from "react"
import { PageProps, graphql } from "gatsby"
import PathContext from "../contexts/path.context"
import Layout from "../layouts/Primary/layout"
import HeroMazeDefault from "../components/hero-default"
import InsideGuidewireExplore from "../components/ig-explore"
import MobileFilter from "../components/faq-listing/mobile-filter"
import FaqListing from "../components/faq-listing"
import LinkedinNoscript from "./noscript/noscript-linkedin"
import FacebookNoscript from "./noscript/noscript-facebook"
import GTMNoscript from "./noscript/noscript-gtm"

export interface IFaqListing {
  title: string
  seoTitle: string
  seoDescription: string
  slug: string
  heroTitle: string
  heroContent: any
  explore: any
  items: any
}

export interface IFaqListingProps {
  contentfulCareersFaqListing: IFaqListing
  allContentfulCareersFaqItem: any
}

interface IFaqListingContext {
  id: string
  slug: string
  locale: string
}

const ContentTemplate: React.FC<PageProps<IFaqListingProps, IFaqListingContext>> = ({ pageContext, path, data }) => {
  const locale = pageContext.locale || "en-US"
  const seoData = {
    title: data.contentfulCareersFaqListing.seoTitle || data.contentfulCareersFaqListing.title,
    description: data.contentfulCareersFaqListing.seoDescription,
    image: "",
  }

  return (
    <PathContext.Provider
      value={{
        current: path,
        slug: pageContext.slug,
        locale: locale,
        activeLanguage: locale.substr(0, 2),
      }}
    >
      <Layout overflowEnable exploreDisable seoData={seoData} dispatch={() => false}>
        <HeroMazeDefault
          heading={data.contentfulCareersFaqListing.heroTitle}
          content={data.contentfulCareersFaqListing.heroContent}
          className="jobs-hero faq"
        />

        <div className="container">
          <div className="row">
            <section className="col-md-6 col-lg-7 main-col">
              <FaqListing faqItems={data.contentfulCareersFaqListing.items} showPagination={true} />
            </section>
            <section className="col-md-5 offset-md-1 col-lg-4 sidebar">
              <MobileFilter />
            </section>
          </div>
        </div>

        <InsideGuidewireExplore items={data.contentfulCareersFaqListing.explore} />
      </Layout>

      {/* Linkedin, Facebook and GTM noscript */}
      <LinkedinNoscript />
      <FacebookNoscript />
      <GTMNoscript />

    </PathContext.Provider>
  )
}

export default ContentTemplate

export const query = graphql`
  query($id: String!) {
    contentfulCareersFaqListing(id: { eq: $id }) {
      id
      title
      slug
      seoTitle
      seoDescription
      heroTitle
      heroContent {
        ...RichTextField
      }
      items {
        ... on ContentfulCareersFaqItem {
          title
          category
          content {
            ...RichTextField
          }
        }
      }
      explore {
        ...ComponentCard
      }
    }
  }
`
